// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest,postRequest} from "@/api/api";
import wxShare from './utils/wxShare'

Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.$toast = vant.Toast
Vue.prototype.$wxShare = wxShare


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

vant.Toast.setDefaultOptions({position:'bottom'})