// 引入 axios
import axios from "axios";



let base = 'finance';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        params: params,
        headers: { 'appid': 'wx6a7f38e0347e6669' },
    })
}
//传送json格式的get请求
export const getWxRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        params: params,
        headers: { 'appid': 'wx6a7f38e0347e6669' ,
        'wx-client-href':location.href},
    })
}
//传送json格式的get请求
export const postRequest=(url,data)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data: data,
        headers: { 'appid': 'wx6a7f38e0347e6669' },

    })
}
